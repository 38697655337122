@font-face {
  font-family: "OverpassBlack";
  src: local("OverpassBlack"),
    url(./assets/fonts/Overpass-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Overpass";
  src: local("Overpass"),
    url(./assets/fonts/Overpass-Regular.ttf) format("truetype");
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */

  font-family: "Overpass", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
     */
  font-family: "Overpass", sans-serif;
}
